<template>
    <div id="accreditEdit">
        <navbar title="授权申请" :back='true'></navbar>
        <div class="accreditEdit-content">
            <div class="edit-inpur-row">
                <div>被授权公司/个人</div>
                <el-input v-model="ListData.Company_Individual"></el-input>
                <div class="line"></div>
            </div>
            <div class="edit-inpur-row">
                <div>被授权项目</div>
                <el-input v-model="ListData.Licensed_Project"></el-input>
                <div class="line"></div>
            </div>
            <div class="edit-inpur-row">
                <div class="add">被授权产品<div class="addBtn" @click="addBtn">添加产品</div>
                </div>
                <div class="line"></div>
            </div>
            <div class="edit-inpur-row" v-for="(item,index) in ListData.Licensed_Products" :key="index">
                <div class="between">
                    <el-input v-model="item.name" style="width:100%"></el-input>
                    <div>
                        <div>产品</div>
                        <div @click="Delete(index)" style="margin-left:10px;cursor: pointer;"><i class="el-icon-delete"></i></div>
                    </div>
                </div>
                <div class="line"></div>
            </div>
            <div class="sample" @click='sampleShow'>查看授权书示例</div>
            <div class="floatBtn">
                <div class="SaveBtn" @click="SaveBtn">提交申请</div>
            </div>
        </div>
    </div>
</template>

<script>
import navbar from "COMPS/accredit_navbar.vue";
import { applyImpower } from "../../api/api";
export default {
    data() {
        return {
            ListData: {
                Company_Individual: "",
                Licensed_Project: "",
                Licensed_Products: [],
            },
        };
    },
    components: {
        navbar,
    },
    methods: {
        addBtn() {
            let params = {
                name: "",
            };
            this.ListData.Licensed_Products.push(params);
        },
        Delete(index) {
            this.ListData.Licensed_Products.splice(index, 1);
        },
        sampleShow() {
            this.$router.push({
                path: "/sampleShow",
            });
        },
        SaveBtn() {
            let Licensed_Products = this.ListData.Licensed_Products;
            let Arr = [];
            for (let i in Licensed_Products) {
                Arr.push(Licensed_Products[i].name);
            }

            if (this.ListData.Company_Individual == "") {
                this.$message({
                    type: "warning",
                    message: "被授权公司/个人不能为空",
                });
                return;
            }
            if (this.ListData.Licensed_Project == "") {
                this.$message({
                    type: "warning",
                    message: "被授权项目不能为空",
                });
                return;
            }
            console.log(Arr);
            if (Arr && Arr.length == 0) {
                this.$message({
                    type: "warning",
                    message: "被授权产品至少包含一项",
                });
                return;
            }
            let params = {
                impowerUnit: this.ListData.Company_Individual,
                impowerGoods: this.ListData.Licensed_Project,
                impowerProject: Arr.join("、"),
            };

            applyImpower(params).then((data) => {
                console.log(data);
                this.$message({
                    type: "success",
                    message: "提交成功",
                });
                this.$router.push({
                    path: "/accreditList",
                });
            });
        },
    },
};
</script>

<style scoped>
.accreditEdit-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    padding-top: 30px;
    padding-bottom: 180px;
}
.edit-inpur-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
    margin-top: 20px;
}
#accreditEdit >>> input {
    border: none;
    font-size: 12px;
}
#accreditEdit >>> .el-input__inner {
    padding: 0;
}
.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(218, 218, 218, 1);
}
.addBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 24px;
    background-color: rgba(255, 96, 29, 1);
    border-radius: 2px;
    color: white;
    font-size: 12px;
    margin-left: 16px;
    margin-bottom: 10px;
}
.add {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.between {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.between > div {
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.sample {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    color: rgba(255, 96, 29, 1);
    font-size: 12px;
    border: 1px solid rgba(255, 96, 29, 1);
    border-radius: 20px;
    margin-top: 300px;
    height: 40px;
    position: fixed;
    bottom: 100px;
    background-color: rgba(255, 255, 255, 1);
}
.floatBtn {
    width: 100%;
    height: 82px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 0;
    padding: 0px 20px;
    border-top: 1px solid rgba(218, 218, 218, 1);
}
.SaveBtn {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(255, 96, 29, 1);
    color: white;
    border-radius: 20px;
    font-size: 14px;
}
</style>